import axios from 'axios';
import {
  CLEAR_OFFERS,
  CLEAR_SEARCH_DATA,
  CLEAR_SELECTED_FILTERS,
  FETCH_AUTO_SUGGESTER,
  GET_FILTERED_HOUSING_DATA,
  GET_FILTERED_HOUSING_DATA_SUCCESS,
  GET_FILTERED_HOUSING_DATA_ERROR,
  LOAD_MORE_OFFERS_ERROR,
  GET_LISTINGS,
  GET_SEARCH_PLACE_TAGS,
  LOAD_MORE_FILTERS_OFFERS,
  LOAD_MORE_OFFERS,
  LOAD_MORE_FILTERS_OFFERS_SUCCESS,
  SET_HOUSING_OFFERS,
  SET_PLACE,
  SET_SEARCH_DATA,
  SET_SEARCH_DETAIL_VIEW,
  SET_SELECTED_FILTERS,
  LOAD_MORE_OFFERS_SUCCESS,
  SET_LOADING,
} from './types';

// After clicking a listings, this function will get the details.
export const setSearchDetailView = (data) => ({
  type: SET_SEARCH_DETAIL_VIEW,
  data,
});

export const setStoreLoading = () => ({
  type: SET_LOADING,
});

export const setSearchData = (data) => ({
  type: SET_SEARCH_DATA,
  data,
});

export const clearSearchData = (data) => ({
  type: CLEAR_SEARCH_DATA,
  data,
});

// Function used after at least one filter is manually applied.
export const getFilteredHousing = (data) => async (dispatch) => {
  dispatch({ type: GET_FILTERED_HOUSING_DATA });

  // eslint-disable-next-line max-len
  try {
    // eslint-disable-next-line max-len
    const result = await axios.get('/api/listings/getFilteredHousing', {
      params: {
        kgs: data.kgs,
        rentOrBuy: data.rentOrBuy,
        houseOrApartment: data.houseOrApartment,
        priceFrom: data.selectedFilters.priceFrom?.length
          ? data.selectedFilters.priceFrom
          : null,
        priceTo: data.selectedFilters.priceTo?.length
          ? data.selectedFilters.priceTo
          : null,
        isNew: data.selectedFilters.isNewHousing,
        rooms: data.selectedFilters?.rooms ? data.selectedFilters?.rooms : null,
        page: data.currentPage ? data.currentPage : '1',
        operator: data.selectedFilters?.operator
          ? data.selectedFilters?.operator
          : null,
      },
    });
    dispatch({ type: GET_FILTERED_HOUSING_DATA_SUCCESS, response: result });
  } catch {
    dispatch({ type: GET_FILTERED_HOUSING_DATA_ERROR, error: true });
  }
};

export const setSelectedFilters = (data) => ({
  type: SET_SELECTED_FILTERS,
  data,
});

// Data has new listings for example loading Berlin or page 2 and updates the store.
export const setHousingOffers = (data) => ({
  type: SET_HOUSING_OFFERS,
  data,
});

export const loadMoreOffers = (payload) => async (dispatch) => {
  dispatch({ type: LOAD_MORE_OFFERS });
  try {
    const result = await axios.get('/api/listings/loadMoreOffers', {
      params: {
        kgs: payload.kgs,
        rentOrBuy: payload.rentOrBuy,
        houseOrApartment: payload.houseOrApartment,
        page: payload.page,
      },
    });
    dispatch({ type: LOAD_MORE_OFFERS_SUCCESS, response: result });
  } catch {
    dispatch({ type: LOAD_MORE_OFFERS_ERROR, error: true });
  }
};

export const loadMoreFiltersOffers = (payload) => async (dispatch) => {
  dispatch({ type: LOAD_MORE_FILTERS_OFFERS });

  // eslint-disable-next-line max-len
  const result = await axios.get('/api/listings/loadMoreFiltersOffers', {
    params: {
      kgs: payload.kgs,
      rentOrBuy: payload.rentOrBuy,
      houseOrApartment: payload.houseOrApartment,
      page: payload.page,
      priceFrom: payload.selectedFilters.priceFrom?.length
        ? payload.selectedFilters.priceFrom
        : null,
      priceTo: payload.selectedFilters.priceTo?.length
        ? payload.selectedFilters.priceTo
        : null,
      isNew: payload.selectedFilters.isNewHousing,
      rooms: payload.selectedFilters?.rooms
        ? payload.selectedFilters?.rooms
        : null,
      operatpr: payload.selectedFilters?.operator ? true : null,
    },
  });
  dispatch({ type: LOAD_MORE_FILTERS_OFFERS_SUCCESS, response: result });
};

// Once you accept the location popup, you coords will be saved in a localstorage. This function should fetch and
// then display the listing of your coords, but doesn't work. => Initial load sets it to berlin and dispatching doesn't
// rerender.
export const searchPlaceUser = (data) => async (dispatch) => {
  try {
    const result = dispatch({
      type: GET_SEARCH_PLACE_TAGS,
      response: await axios.get(
        `/api/listings/searchPlaceTag?lng=${data.lon}&lat=${data.lat}`,
      ),
    });
    // if (e || !data) throw e;
    const res = result.response.data.data.items.find(
      (item) => item.areaType === 'kgs8',
    );
    if (!res) return null;
    const res2 = dispatch({
      type: GET_LISTINGS,
      response: await axios.get('/api/listings/getListings', {
        params: {
          kgs: res.kgs,
          rentOrBuy: 'rent',
          houseOrApartment: 'apartment',
        },
      }),
    });
    return dispatch({
      type: SET_HOUSING_OFFERS,
      data: res2.response.data.data,
    });
  } catch (e) {
    return console.log(e);
  }
};

// Fetches autoSuggest. The response is used in a .then(res) function.
export const fetchAutoSuggestText = (data) => async (dispatch) => {
  dispatch({ type: FETCH_AUTO_SUGGESTER });
  const result = await axios.get(`/api/autoSuggest/autoSuggest?data=${data}`);
  return result;
};

export const clearSelectedFilters = () => ({
  type: CLEAR_SELECTED_FILTERS,
});

export const setCurrentPlace = (data) => ({
  type: SET_PLACE,
  data,
});
export const clearOffers = () => ({
  type: CLEAR_OFFERS,
});
