import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Collapse } from 'react-bootstrap';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';
import { clearSelectedFilters } from '../../../store/market/actions';

const Menu = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const clearStore = useCallback(() => {
    dispatch(clearSelectedFilters());
  }, [dispatch]);

  return (
    <div className={styles.navBlock}>
      <button
        type="button"
        aria-controls="example-collapse-text"
        aria-expanded={isOpen}
        onClick={openMenu}
        className={classNames(
          'navbar-toggler navbar-toggler-right g-line-height-1 g-brd-none' +
            ' g-pa-0 g-pos-abs g-top-minus-3 g-right-0 collapsed',
          styles.toggleBtn,
          { [styles.toggleBtnActive]: isOpen },
        )}
      >
        <span />
      </button>
      <Link href="/">
        <img
          src="/assets/img/logo.svg"
          alt="logo"
          className={styles.logo}
          data-cy="logo-link"
        />
      </Link>
      <Collapse in={isOpen}>
        <div id="example-collapse-text" className={styles.show}>
          <div
            className={classNames(
              'align-items-center flex-sm-row g-pt-10 g-pt-5--lg' + 'collapse',
              styles.blockMenu,
            )}
          >
            <ul className="navbar-nav text-uppercase g-pos-rel g-font-weight-600 ml-auto text-left">
              <li className="hs-has-mega-menu ">
                <Link href="https://www.iib-institut.de/lagebericht">
                  <a
                    style={{ paddingLeft: '0' }}
                    role="presentation"
                    className={`nav-link g-px-0, ${styles.textColor}`}
                    data-cy="podcast-link"
                  >
                    Immobilienpodcast
                  </a>
                </Link>
              </li>
              <li className="hs-has-mega-menu ">
                <Link href="/immobilien">
                  <a
                    style={{ paddingLeft: '0' }}
                    role="presentation"
                    onClick={clearStore}
                    className={`nav-link g-px-0, ${styles.textColor}`}
                    data-cy="immo-link"
                  >
                    Immobilien
                  </a>
                </Link>
              </li>
              <li className="hs-has-mega-menu">
                <Link href="/mietspiegel">
                  <a
                    style={{ paddingRight: '0' }}
                    role="presentation"
                    onClick={clearStore}
                    className={`nav-link g-py-7 g-px-0, ${styles.textColor}`}
                    data-cy="miet-link"
                  >
                    Marktmietspiegel
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default Menu;
