import PropTypes from 'prop-types';

import Head from './Head';
import styles from './Layout.module.scss';
import TopBar from './TopBar';
import FooterBar from './FooterBar';

const Layout = ({ title, children, description }) => (
  <>
    <Head title={title} description={description} />
    <div className={styles.wrapper}>
      <TopBar />
      <div className={styles.content}>{children}</div>
      <FooterBar />
    </div>
  </>
);

Layout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any.isRequired,
};

Layout.defaultProps = {
  title: 'wpreis',
  description: '',
};

export default Layout;
