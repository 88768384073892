import Menu from '../../Menu';

const TopBar = () => (
  <div>
    <header id="js-header" className="u-header u-header--static">
      <div className="u-header__section u-header__section--light g-transition-0_3 g-py-10">
        <nav className="js-mega-menu navbar-expand-lg hs-menu-initialized hs-menu-horizontal">
          <div className="container w-100">
            <Menu />
          </div>
        </nav>
      </div>
    </header>
  </div>
);

export default TopBar;
