/* eslint-disable */
import NextHead from 'next/head';
import { string } from 'prop-types';

const Head = ({ title, description }) => (
  <NextHead>
    <title>{title}</title>
    <meta charSet="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="description" content={description} />
    <meta property="og:title" content={title} key="ogtitle" />
    <meta property="og:description" content={description} key="ogdesc" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <link rel="icon" type="image/png" href="/logo.png" />
  </NextHead>
);

Head.propTypes = {
  title: string,
  description: string,
};

Head.defaultProps = {
  title: '',
  description: '',
};

export default Head;
